export const PRODUCTS = [
    {
        id: 0,
        name: "Burton Parker",
        subtitle: "Burton Karas Parker",
        available: true,
        inventory: 1,
        price: "$123.99",
        image: "/assets/images/funy-dog.jpg",
        secondaryImage: "/assets/images/burton-karas-parker-add-me.jpg",
        description: ""
    }
];